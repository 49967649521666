.product-slider-container {
    margin-top: 50px;
    height: 400px;
    /* margin: 0 auto; */
    width: 100%; /* Adjust the width as needed */
    text-align: center;
    background-color: #031026;
    color: white;
  }
  
  .product-slider-container h2 {
    font-size: 2.5rem;
    margin-bottom: 10px;
    color: white;
  }
  
  .product-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 120px;
    width: 100px;
    padding-top:20px ;
  }
  
  .product-item img {
    height: 240px;
    width: 240px;
    align-items: center;
    object-fit: cover;
    border-radius: 8px;
    margin-bottom: 5px;
  }
  
  /* .product-item p {
    font-size: 1.3rem;
    text-align: center;
    margin: 0;
  } */

  @media screen and (max-width: 650px) {
    .product-item img {
        height: 200px;
        width: 200px;
        align-items: center;
        object-fit: cover;
        border-radius: 8px;
        margin-bottom: 5px;
      }

      .product-slider-container {
        height: 330px;
        margin: 20px auto;
        width: 100%; /* Adjust the width as needed */
        text-align: center;
        background-color: #1A1C20;
        color: white;
      }
      .product-item {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 120px;
        width: 100px;
        padding-top: 10px ;
      }
  }
  