
.cart-btn {
    background: #cda9ac;
    padding: 10px;
    outline: 0;
    border: 0;
    margin: -1px;
    border-radius: 2px;
    text-transform: uppercase;
    letter-spacing: 1px;
    color: #f5f5f5;
    cursor: pointer;
  }
  .cart-btn:hover {
    background: #ba7e7e;
    transition: all 0.4s ease-in-out;
  }

  .product__type{
    text-transform: capitalize;
    display: inline-block;
    min-width: 110px;
    font-weight: 500;
    font-size: 14px;
  }

  .product-type{
    margin-bottom: 0 !important;
  }