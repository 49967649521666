.future-head {
    text-align: left;
  height: 50px; 
  display: flex;
  flex-direction: column;
  justify-content: left;
}

.underline {
    border-bottom: 2px solid #031026;
    width: 70px; 
    margin-top: 15px; 
  }

.future{
    background-color: white;
}

.card-img-top{
  height: 250px;
}

.box-with-shadow{
  height: 350px;
}

.products-img{
  height: 350px !important;
  width: 100%;
}

.container{
  padding: 0px;
}

@media screen and (max-width: 750px) {
  .card-img-top{
    height: 150px !important;
  }
  .box-with-shadow{
    height: 250px !important;
  }
  .products-img{
    height: 200px !important;
    width: 100%;
  }

  .container{
    width: 100% !important;
    padding: 0px;
  }
  .info{
    margin-top: 5px;
  }
}