
  .about-info{
    color: black;
  }

  .view-btn{
    text-decoration: none;
    color: black;
  }

  .about-section{
    margin-top: -35px !important;
  }

  .card {
    background-color: white; /* Default background color */
    transition: background-color 0.8s ease; /* Smooth transition effect */
  }
  
  .card:hover {
    background-color: #9BD8F3; /* Light yellow background color on hover */
  }
  
  .carousel-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    /* background-color: rgba(0, 0, 0, 0.5); */
    z-index: 0;
  }

  .slide-img{
    height: 450px;
  }

  @media screen and (max-width: 768px) {
    .carousel-caption {
      text-align: center;
      width: 100%; /* Set width to fill the entire width of the container */
      top: 50%;
      left: 0;
      transform: translate(0, -50%);
    }
  
    .carousel-caption h1 {
      font-size: 2.5rem; /* Adjust font size for smaller screens */
    }
  
    .carousel-caption h3 {
      font-size: 1.8rem; /* Adjust font size for smaller screens */
    }
    .slide-img{
      height: 250px;
    }

    .carousel-overlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 50%;
      height: 60%;
      /* background-color: rgba(0, 0, 0, 0.5); */
      z-index: 0;
    }

  .first-four-cards{
    padding: 0px !important;
  }

  }

  .header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .future-head {
    margin: 0; /* Reset default margin */
    width: 450px;
    font-weight: bold;
  }

  .tsp-button{
    padding: 10px 20px;
    background-color: #D9A04C;
    color: white;
    border: none;
  }
  
  .about-info {
    margin-top: 15px;
    text-align: left;
    background-color: white;
  }