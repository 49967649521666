.card {
    overflow: hidden;
  }
  
  .card-img-top {
    transition: transform 0.4s ease;
  }
  
  .card-img-top:hover {
    transform: scale(0.95); /* Adjust the scale value as needed */
  }

.container{
    /* margin-top: 55px; */
}

 .future-head {
  width: 100%;
    text-align: center;
  height: 50px; 
  display: flex;
  flex-direction: column;
  justify-content: center;
  }

  .future-p{
    height: 50px;
    text-align: left;
  }
/*
  .future {
    position: relative;
    padding: 20px 0;
    text-align: center;
    height: 505px;
  }
 */


  /* .future::before {
    content: '';
    position: absolute;
    left: -30%;
    right: -6%;
    top: 0;
    bottom: 0;
    background-color: rgb(167, 223, 239);
    z-index: -1;
  }
  
  .underline {
    width: 12%;
    height: 4px;
    background-color: rgb(0, 0, 0);
    margin: auto;
    margin-top: 1px;
    margin-bottom: 4px;
  }  */

  .underline{
    margin-top: 15px !important;
    width: 18% !important;
  }

body {
    font-family: Arial, Helvetica, sans-serif;
    margin: 0;
  }
  
  html {
    box-sizing: border-box;
  }
  
  *, *:before, *:after {
    box-sizing: inherit;
  }
  
  .column {
    float: left;
    width: 25.0%;
    margin-bottom: 16px;
    padding: 0 8px;
  }

  .column-45{
    float: left;
    width: 50.0%;
    margin-bottom: 16px;
    padding: 0 8px;
  }
  
  .card {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    margin: 8px;
  }
  
  .about-section {
    padding: 50px;
    text-align: center;
    /* background-color: #ffffff; */
    color: rgb(8, 8, 8);
  }
  
  .container {
    padding: 0 16px;
  }
  
  .container::after, .row::after {
    content: "";
    clear: both;
    display: table;
  }
  
  .title {
    color: grey;
  }
  
  .button {
    border: none;
    outline: 0;
    display: inline-block;
    padding: 8px;
    color: white;
    background-color: #000;
    text-align: center;
    cursor: pointer;
    width: 100%;
  }
  
  .button:hover {
    background-color: #555;
  }
  .background{
    background-color: #FAF8F3 !important;
}

  @media screen and (max-width: 650px) {
    .column {
      width: 100%;
      display: block;
    }
    .second-four-cards{
      padding: 0px !important;
    }
    .second-row{
      margin-top: 45px !important;
    }
    .underline{
      margin-top: 20px !important;
    }
  }