.custom-slider-container {
    margin-top: 50px;
    text-align: center;
    background-color: white;
    color: #031026;
    width: 100%;
    height: 320px;
  }
  
  .custom-slider-container h2 {
    font-size: 2.5rem;
    margin-bottom: 10px;
    color: #031026;
    font-weight: bold;
  }
  
  .custom-slider-item {
    display: flex;
    align-items: center; /* Vertically center images */
    justify-content: center; /* Horizontally center images */
    width: auto;
    height: 90%; /* Make sure the item takes up the full height of the container */
    padding: 20px;
  }
  
  .custom-slider-item img {
    max-height: 90%; /* Allow the image to scale down if it's too large */
    max-width: 100%; /* Allow the image to scale down if it's too large */
    object-fit: contain; /* Ensures the entire logo fits within the container without being cropped */
    border-radius: 8px;
    margin-bottom: 20px;
  }
  
  @media screen and (max-width: 650px) {
    .custom-slider-item img {
      max-height: 150px; /* Adjusted for smaller screens */
      width: auto;
      object-fit: contain; /* Maintains aspect ratio */
    }
  
    .custom-slider-container {
      margin: 20px auto;
      background-color: white;
    }
  
    .custom-slider-item {
      padding-top: 10px;
    }
  }
  