
   body {
            font-family: "Poppins", sans-serif;
            background-color: #FAF8F3;
        }

        .background{
            background-color: #FAF8F3;
        }

        .textColor {
            color: #030F27;
        }

        .navbarBgDark {
            background: #030F27;
        }

        .navbar-nav .nav-link.active {
            color: #EDA72F;
        }

        .nav-link:hover {
            color: #EDA72F;
        }

        .nav-link {
            color: #fff;
        }

        .getBtn {
            color: #fff;
        }

        .sideLine {
            border-right: 1px solid #030F27;
        }

        .iconHeight {
            height: 24px;
            width: 24px;
            color: rgb(43, 49, 60);
            /* background-color: #030F27; */
        }

        @media screen and (min-width: 650px) {
            .sideLine {
                border-right: none;
            }

            .iconHeight {
                height: 46px;
                width: 46px;
            }
        }

        .logo-img {
          height: 50px;
          width: 220px;
          max-width: 800px;
        }
        
        @media (max-width: 767px) {
          .logo-img {
            display: none;
          }
        }